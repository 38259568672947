import request from '@/utils/request'

export function orderList (query) {
  return request({
    url: '/shop/back_end_order/',
    method: 'get',
    params: query
  })
}
export function orderStatisticsList (query) {
  return request({
    url: '/wms/warehouse/pick_list/order/goods/',
    method: 'get',
    params: query
  })
}
export function orderIncomeSummary (query) {
  return request({
    url: '/shop/order/income_summary/',
    method: 'get',
    params: query
  })
}

export function orderToExamine (parameter) {
  return request({
    url: '/shop/order/examine/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderDetail (order_id) {
  return request({
    url: '/shop/back_end_order/detail/?order_id=' + order_id,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function putOrderStatus (status, goods_type, order_id) {
  return request({
    url: '/shop/back_end_order/detail/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: {
      order_id: order_id,
      goods_type: goods_type,
      status: status
    }
  })
}

export function parentCategoryName () {
  return request({
    url: '/wechat/parent_category/name/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function order_refund (parameter) {
  return request({
    url: '/wechat/order/refund/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function detailGoods (id) {
  return request({
    url: '/goods/detail/',
    method: 'get',
    params: { id }
  })
}

export function editGoods (data) {
  return request({
    url: '/wechat_mall_goods/',
    method: 'put',
    data
  })
}

export function listCatAndBrand () {
  return request({
    url: '/goods/catAndBrand',
    method: 'get'
  })
}

export function notifyList (query) {
  return request({
    url: '/shop/back_notify/',
    method: 'get',
    params: query
  })
}
export function createNotify (parameter) {
  return request({
    url: '/shop/back_notify/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function putNotify (parameter, id) {
  return request({
    url: '/shop/back_notify/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteNotify (id) {
  return request({
    url: '/shop/back_notify/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 退款列表
export function refundList (query) {
  return request({
    url: '/shop/order/back_end_apply_refund/',
    method: 'get',
    params: query
  })
}

// 退款
export function doRefund (parameter) {
  return request({
    url: '/shop/refund/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
// 退款
export function doBackApplyRefund (parameter) {
  return request({
    url: '/shop/order/back_end_apply_refund/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
// 检验完整性
export function putRefund (parameter, refund_id) {
  return request({
    url: '/shop/refund/' + refund_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
// 退货地址
export function refundAddressList (query) {
  return request({
    url: '/shop/refund_address/',
    method: 'get',
    params: query
  })
}
export function createRefundAddress (parameter) {
  return request({
    url: '/shop/refund_address/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function putRefundAddress (parameter, id) {
  return request({
    url: '/shop/refund_address/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteRefundAddress (id) {
  return request({
    url: '/shop/refund_address/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function refundExamine (parameter) {
  return request({
    url: '/shop/refund_examine/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

// 退货商品列表
export function refundGoodsList (query) {
  return request({
    url: '/shop/order/refund/goods/',
    method: 'get',
    params: query
  })
}

// 退货商品放至退货区
export function createRefundGoods (parameter) {
  return request({
    url: '/wms/refund_order/goods_to_warehouse/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function putRefundGoods (parameter, id) {
  return request({
    url: '/shop/refund_goods/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteRefundGoods (id) {
  return request({
    url: '/shop/refund_goods/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function putReceived (parameter) {
  return request({
    url: '/shop/order/back_end_apply_refund/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderToWarehouse (parameter) {
  return request({
    url: '/shop/order_to_warehouse/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderToSupplier (parameter) {
  return request({
    url: '/shop/back_end_order/push_to_supplier/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderToWarehouseConfig (query) {
  return request({
    url: '/shop/config/',
    method: 'get',
    params: query
  })
}

export function putOrderToWarehouseConfig (parameter, id) {
  return request({
    url: '/shop/config/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 导出订单
 */
export function wechatOrderExport (parameter) {
  return request({
    url: '/shop/back_end_order_export/',
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}

export function downloadOrderLogisticsImportExcel () {
  return request({
    url: '/shop/back_end_order/import_download_excel/',
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob'
  })
}

/**
 *
 * @param parameter
 * @returns {AxiosPromise}
 */
export function orderLogisticsImport (parameter) {
  return request({
    url: '/shop/back_end_order/import_download_excel/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderLogisticsTrajectory (query) {
  return request({
    url: '/shop/order_logistics_trajectory/',
    method: 'get',
    params: query
  })
}

export function putOrderLogistics (data) {
  return request({
    url: '/shop/back_end_order/update_logistics/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function orderLogisticsList (query) {
  return request({
    url: '/shop/back_end_order/logistics/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: query
  })
}

export function addOrderLogistics (data) {
  return request({
    url: '/shop/back_end_order/logistics/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function editOrderLogistics (id, data) {
  return request({
    url: '/shop/back_end_order/logistics/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function deleteOrderLogistics (id) {
  return request({
    url: '/shop/back_end_order/logistics/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 虚拟商品
export function orderVirtualList (query) {
  return request({
    url: '/shop/back_end_order/virtual/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: query
  })
}

export function addOrderVirtual (data) {
  return request({
    url: '/shop/back_end_order/virtual/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

export function deleteOrderVirtual (id) {
  return request({
    url: '/shop/back_end_order/virtual/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
